import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export default function NotFound(): JSX.Element {
    return (
        <Content>
            <h1>404</h1>
            <h3>page not found</h3>
        </Content>
    );
}
